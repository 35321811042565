<template>
  <v-form
    id="manage-booking-event-form"
    ref="bookingForm"
    @submit.prevent="fire"
  >
    <v-container tag="section" fluid class="pb-12">
      <v-row v-if="isSingleRecurringBookingFromGroupModeUpdate">
        <v-col>
          <calendesk-information-message
            color="orange"
            additional-class="white--text"
            icon-color="white"
            icon="$alert"
          >
            {{ $trans("single_booking_in_group_edit_warning") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="text-h6 pb-0">
              {{ $trans("booking_form_service_question") }}
            </v-col>
            <v-col cols="12">
              <services-field
                v-model="form.service"
                :disabled="
                  disabledForm ||
                  isSingleRecurringBookingFromGroupModeUpdate ||
                  (eventUpdateGroupBookingMode && recurringBooking)
                "
                :show-only-group-services="
                  eventUpdateGroupBookingMode ||
                  isSingleBookingFromGroupModeUpdate
                "
                :show-only-one-to-one-services="
                  eventUpdateOneToOneBookingMode &&
                  !isSingleBookingFromGroupModeUpdate
                "
                :rules="[rules.required]"
                :label="$trans('select_service_from_list_title')"
                chips
                deletable-chips
                @change="handleServiceChange"
              />
            </v-col>
            <v-col cols="12" class="text-h6 mt-2 pb-0">
              {{ $trans("booking_form_employee_question") }}
            </v-col>
            <v-col cols="12">
              <employee-field
                v-model="form.employee"
                :label="$trans('select_employee_label')"
                :rules="[rules.required]"
                :disabled="
                  !hasPermission(employeePermissions.SHOW_EMPLOYEES) ||
                  disabledForm ||
                  (eventUpdateGroupBookingMode && recurringBooking) ||
                  isSingleRecurringBookingFromGroupModeUpdate
                "
                chips
                deletable-chips
              />
              <div class="my-2">
                <calendesk-information-message
                  v-if="showEmployeeNoIntegrationWarning"
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                  :class="{
                    'mb-1': employeeIsNotAssignedToService,
                  }"
                >
                  {{ $trans("no_integration_employee_warning") }}
                </calendesk-information-message>

                <calendesk-information-message
                  v-if="employeeWasSelectedManuallyByCustomer"
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                  :class="{
                    'mb-1': employeeIsNotAssignedToService,
                  }"
                >
                  {{ $trans("user_requested_employee_title") }}
                </calendesk-information-message>

                <calendesk-information-message
                  v-if="employeeIsNotAssignedToService"
                  color="orange"
                  additional-class="white--text"
                  icon-color="white"
                  icon="$alert"
                >
                  {{ $trans("employee_not_available_in_this_service_error") }}
                </calendesk-information-message>
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12">
              <calendesk-information-message
                v-if="datesInThePast"
                color="orange"
                additional-class="white--text"
                icon-color="white"
                icon="$alert"
              >
                {{ $trans("booking_date_in_the_past_warning") }}
              </calendesk-information-message>
              <booking-form-date
                :form="form"
                :label="$trans('booking_form_date_question')"
                :disabled-form="disabledForm"
                :event-update-single-recurring-booking-from-group-mode="
                  isSingleRecurringBookingFromGroupModeUpdate
                "
                :event-update-mode="eventUpdateMode"
                @update="form = $event"
              />
            </v-col>
            <v-col cols="12" class="text-h6 mt-2 pb-0">
              {{ $trans("booking_form_user_question") }}
            </v-col>
            <v-col cols="12">
              <clients-field
                v-if="!isGroupBookingEventType"
                v-model="form.user"
                :label="$trans('select_customer_label')"
                :rules="[rules.required]"
                :disabled="
                  disabledForm ||
                  !!form.groupId ||
                  isSingleBookingFromGroupModeUpdate
                "
                chips
                deletable-chips
                @userAdded="handleNewUserAdded"
              />
              <clients-field
                v-else
                v-model="form.users"
                :label="$trans('select_customers_label')"
                :disabled="
                  disabledForm ||
                  !!form.groupId ||
                  (eventUpdateMode && recurringBooking)
                "
                :rules="[rules.required, rules.requiredArray]"
                multiple
                chips
                deletable-chips
                @userAdded="handleNewUserAdded"
              />
            </v-col>
            <v-col
              v-if="
                form.service &&
                form.service.locations &&
                form.service.locations.length > 0
              "
              cols="12"
            >
              <div class="text-h6">
                {{ $trans("booking_location_question") }}
              </div>
              <calendesk-locations-selection
                v-model="form.serviceLocationId"
                :form="form"
                :rules="[rules.required]"
                :disabled="
                  disabledForm || isSingleRecurringBookingFromGroupModeUpdate
                "
              />
            </v-col>
            <v-col cols="12" class="text-h6 mt-2 pb-0">
              {{ $trans("booking_form_additional_question") }}
            </v-col>
            <template v-if="!eventUpdateGroupBookingMode">
              <v-col cols="12">
                <single-status-field
                  v-model="form.status"
                  :disabled="disabledForm"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <tags-field
                  v-model="form.tags"
                  :disabled="disabledForm"
                  small-chips
                  deletable-chips
                  multiple
                  @tagAdded="handleNewTagAdded"
                />
              </v-col>
            </template>
            <v-col
              v-if="
                !recurringBooking &&
                !eventUpdateMode &&
                form.service &&
                form.service.payment_required &&
                form.service.awaiting_booking_payment_time > 0 &&
                form.status === bookingStatuses.payment
              "
              cols="12"
            >
              <calendesk-information-message
                color="orange"
                additional-class="white--text"
                icon-color="white"
                icon="$alert"
              >
                {{ $trans("booking_awaiting_payment_warning") }}
                {{ form.service.awaiting_booking_payment_time | duration }}.
              </calendesk-information-message>
            </v-col>
            <v-col
              v-if="
                recurringBooking &&
                !eventUpdateMode &&
                form.service &&
                form.service.payment_required_recurring_bookings &&
                form.service.payment_deadline_before_start > 0 &&
                form.status === bookingStatuses.payment
              "
              cols="12"
            >
              <calendesk-information-message
                color="orange"
                additional-class="white--text"
                icon-color="white"
                icon="$alert"
              >
                {{ $trans("payment_deadline_before_start_warning") }}
                {{ form.service.payment_deadline_before_start | duration }}.
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row v-if="!eventUpdateGroupBookingMode">
            <v-col>
              <v-textarea
                v-model="form.description"
                :label="$trans('private_notes')"
                outlined
                rows="5"
                :placeholder="$trans('you_can_add_note_here')"
                :disabled="disabledForm"
                :rules="form.description ? [rules.maxChars(5000)] : []"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-4"> </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col class="mt-0 pt-0 pb-0 mb-0">
              <v-checkbox
                v-model="form.skipNotifications"
                dense
                class="mt-0"
                :disabled="disabledForm || datesInThePast"
                hide-details
              >
                <template #label>
                  <v-tooltip right max-width="350">
                    <template #activator="{ on }">
                      <div v-on="on">
                        <span v-if="eventUpdateMode">{{
                          $trans("booking_updated_no_notifications_label")
                        }}</span>
                        <span v-else>{{
                          $trans("booking_created_no_notifications_label")
                        }}</span>
                      </div>
                    </template>
                    {{ $trans("booking_no_notifications_tooltip") }}
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row
            v-if="!eventUpdateMode || (eventUpdateMode && recurringBooking)"
            no-gutters
          >
            <v-col class="pt-0 mt-0">
              <v-checkbox
                v-model="recurringBooking"
                dense
                class="mt-0"
                :label="$trans('recurring_booking')"
                :disabled="
                  disabledForm ||
                  eventUpdateMode ||
                  isSingleRecurringBookingFromGroupModeUpdate
                "
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="recurringBooking">
            <v-col class="d-flex align-center align-content-center" cols="12">
              <div
                class="text-no-wrap mr-2 v-label"
                :class="{
                  'checkbox-intro': $vuetify.breakpoint.mdAndUp,
                }"
              >
                {{ $trans("repeat_every") }}
              </div>
              <v-text-field
                v-model="recurringBookingRepeatEvery"
                :rules="[
                  rules.maxNumber(
                    recurringBookingRepeatEvery,
                    recurringBookingRepeatEveryMaxNumber,
                  ),
                  rules.minNumber(recurringBookingRepeatEvery, 1),
                ]"
                outlined
                hide-details="auto"
                type="number"
                dense
                class="small-width-input"
                :disabled="
                  disabledForm ||
                  isSingleRecurringBookingFromGroupModeUpdate ||
                  datesInThePast
                "
              />
              <v-select
                v-model="recurringBookingSelectedFrequency"
                class="ml-2"
                :class="{
                  'medium-width-input': $vuetify.breakpoint.mdAndUp,
                }"
                hide-details="auto"
                :items="recurringBookingFrequencies"
                outlined
                :rules="[rules.required]"
                dense
                :disabled="
                  disabledForm ||
                  isSingleRecurringBookingFromGroupModeUpdate ||
                  datesInThePast
                "
              />
            </v-col>
          </v-row>
          <v-row v-if="recurringBooking" no-gutters>
            <v-col
              class="d-flex align-center align-content-center mb-4"
              cols="12"
            >
              <v-radio-group
                v-model="recurringBookingEndsSelectedType"
                dense
                hide-details
                :disabled="
                  disabledForm ||
                  isSingleRecurringBookingFromGroupModeUpdate ||
                  datesInThePast
                "
              >
                <v-radio :value="recurringBookingEndsSelectedTypes.OCCURRENCES">
                  <template #label>
                    <div class="text-no-wrap mr-2 checkbox-intro">
                      {{ $trans("ends_after_occurrences_1") }}
                    </div>
                    <v-text-field
                      v-model="recurringBookingEndsAfter"
                      outlined
                      hide-details="auto"
                      :disabled="
                        disabledForm ||
                        recurringBookingEndsSelectedType ===
                          recurringBookingEndsSelectedTypes.DATE ||
                        isSingleRecurringBookingFromGroupModeUpdate ||
                        datesInThePast
                      "
                      type="number"
                      dense
                      :rules="[
                        rules.maxNumber(
                          recurringBookingEndsAfter,
                          isZoomSelected ? 50 : 100,
                        ),
                        rules.minNumber(recurringBookingEndsAfter, 1),
                      ]"
                      class="small-width-input"
                    />
                    <div class="text-no-wrap ml-2">
                      {{ $trans("ends_after_occurrences_2") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="recurringBookingEndsSelectedTypes.DATE">
                  <template #label>
                    <div class="text-no-wrap mr-2 checkbox-intro">
                      {{ $trans("ends_at_date") }}
                    </div>
                    <calendar-input
                      v-model="recurringBookingEndsAt"
                      :label="$trans('date')"
                      :disabled="
                        disabledForm ||
                        recurringBookingEndsSelectedType ===
                          recurringBookingEndsSelectedTypes.OCCURRENCES ||
                        isSingleRecurringBookingFromGroupModeUpdate ||
                        datesInThePast
                      "
                      :min="minDate"
                      :max="maxDate"
                      dense
                    />
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <template v-if="!eventUpdateGroupBookingMode">
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                  v-model="wantsProducts"
                  dense
                  class="mt-0"
                  :label="$trans('upselling_title')"
                  :disabled="disabledForm"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row v-if="wantsProducts">
              <v-col cols="12">
                <plus-field
                  v-model="form.products"
                  :disabled="disabledForm"
                  force-list-to-top
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  :label="$trans('simple_store_products')"
                  action="simpleStore/fetchAllProducts"
                  getter="simpleStore/getAllProducts"
                  :dialog-action="openManageSimpleStoreProductModal"
                  :no-data-text="$trans('add_simple_store_products_with_plus')"
                  :reload-on="getRefreshSimpleStoreProductListStatus"
                  :hide-actions="!loggedUserCanAddSimpleProducts"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6">
          <calendesk-information-message
            v-if="datesInThePast"
            color="orange"
            additional-class="white--text"
            icon-color="white"
            icon="$alert"
          >
            {{ $trans("booking_date_in_the_past_warning") }}
          </calendesk-information-message>
          <booking-form-date
            :form="form"
            :label="$trans('booking_form_date_question')"
            :disabled-form="disabledForm"
            :event-update-single-recurring-booking-from-group-mode="
              isSingleRecurringBookingFromGroupModeUpdate
            "
            :event-update-mode="eventUpdateMode"
            @update="form = $event"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";
import { mapActions, mapGetters } from "vuex";
import { bookingStatuses } from "@/calendesk/prototypes/bookingStatuses";
import event from "@/views/dashboard/pages/Calendar/components/ManageEvent/shared/event";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import TagsField from "@/components/Forms/TagsField.vue";
import BookingFormDate from "@/views/dashboard/pages/Calendar/components/ManageEvent/components/BookingFormDate.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import CalendarInput from "@/lib/calendesk-js-library/components/CalendarInput.vue";
import PlusField from "@/components/Forms/PlusField.vue";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import CalendeskLocationsSelection from "@/components/Event/CalendeskLocationsSelection.vue";
import SingleStatusField from "@/components/Forms/SingleStatusField.vue";

export default {
  name: "ManageBookingEvent",
  components: {
    SingleStatusField,
    CalendeskLocationsSelection,
    PlusField,
    CalendarInput,
    CalendeskInformationMessage,
    BookingFormDate,
    TagsField,
    ClientsField,
    EmployeeField,
    ServicesField,
  },
  mixins: [event],
  data() {
    return {
      bookingStatuses,
      wantsProducts: false,
      shouldValidate: false,
    };
  },
  computed: {
    ...mapGetters({
      getRefreshSimpleStoreProductListStatus:
        "simpleStore/getRefreshSimpleStoreProductListStatus",
    }),
    showEmployeeNoIntegrationWarning() {
      return (
        this.form.employee &&
        ((!this.form.employee.zoom_enabled && this.isZoomSelected) ||
          (!this.form.employee.google_calendar_enabled &&
            this.isGoogleMeetSelected) ||
          (!this.form.employee.teams_business_enabled &&
            this.isTeamsSelected) ||
          (!this.form.employee.whatsapp_number && this.isWhatsAppSelected) ||
          (!this.form.employee.skype_business_enabled &&
            !this.form.employee.skype_consumer_enabled &&
            this.isSkypeSelected))
      );
    },
    employeeWasSelectedManuallyByCustomer() {
      return (
        this.form.data &&
        this.adminConfiguration &&
        this.form.data.user_requested_employee &&
        this.adminConfiguration.user_requested_employee_enabled
      );
    },
    employeeIsNotAssignedToService() {
      if (
        this.form.service &&
        this.form.employee &&
        this.form.employee.services
      ) {
        const employeeServices = this.form.employee.services.map(
          (service) => service.id,
        );

        return !employeeServices.includes(this.form.service.id);
      }

      return false;
    },
    dialogSize() {
      return dialogSize;
    },
    eventUpdateGroupBookingMode() {
      return (
        this.eventUpdateMode &&
        this.form.initialType === this.$helpers.eventTypes.groupBooking
      );
    },
    eventUpdateOneToOneBookingMode() {
      return (
        this.eventUpdateMode &&
        this.form.initialType === this.$helpers.eventTypes.booking
      );
    },
    eventUpdateMode() {
      return (
        !!this.form.id ||
        (!!this.form.multiSlotGroupId && this.isGroupBookingEventType)
      );
    },
    isGroupBookingEventType() {
      return this.form.type === this.$helpers.eventTypes.groupBooking;
    },
    isSingleRecurringBookingFromGroupModeUpdate() {
      return (
        !!this.form.multiSlotGroupId && !!this.form.id && this.recurringBooking
      );
    },
    isSingleBookingFromGroupModeUpdate() {
      return !!this.form.multiSlotGroupId && !!this.form.id;
    },
    isZoomSelected() {
      return this.isServiceLocationTypeSelected(
        this.$helpers.serviceLocationTypes.ZOOM,
      );
    },
    isTeamsSelected() {
      return this.isServiceLocationTypeSelected(
        this.$helpers.serviceLocationTypes.TEAMS,
      );
    },
    isWhatsAppSelected() {
      return this.isServiceLocationTypeSelected(
        this.$helpers.serviceLocationTypes.WHATSAPP,
      );
    },
    isSkypeSelected() {
      return this.isServiceLocationTypeSelected(
        this.$helpers.serviceLocationTypes.SKYPE,
      );
    },
    isGoogleMeetSelected() {
      return this.isServiceLocationTypeSelected(
        this.$helpers.serviceLocationTypes.GOOGLE_MEET,
      );
    },
    recurringBookingRepeatEveryMaxNumber() {
      if (this.isZoomSelected) {
        if (this.recurringBookingSelectedFrequency === "DAILY") {
          return 90;
        }

        if (this.recurringBookingSelectedFrequency === "WEEKLY") {
          return 12;
        }

        if (this.recurringBookingSelectedFrequency === "MONTHLY") {
          return 3;
        }
      }

      return 100;
    },
  },
  watch: {
    "form.service"(val) {
      if (
        !this.isSingleBookingFromGroupModeUpdate &&
        val &&
        val.max_people > 1
      ) {
        this.form.type = this.$helpers.eventTypes.groupBooking;
      } else {
        this.form.type = this.$helpers.eventTypes.booking;
      }

      let locationExists = false;
      if (val && val.locations) {
        // Check if this.form.serviceLocationId is in val.locations before setting to null
        locationExists = val.locations.some(
          (location) => location.id === this.form.serviceLocationId,
        );
      }

      if (!locationExists) {
        this.form.serviceLocation = null;
        this.form.serviceLocationId = null;
      }
    },
    "form.serviceLocationId"(serviceLocationId) {
      if (
        serviceLocationId &&
        this.form.service &&
        this.form.service.locations
      ) {
        this.form.serviceLocation = this.form.service.locations.find(
          (location) => location.id === serviceLocationId,
        );
      } else {
        this.form.serviceLocation = null;
      }
    },
  },
  mounted() {
    EventBus.$on("MANAGE_BOOKING_EVENT_SCROLL_TO_TOP", () => {
      const element = document.getElementById("manage-booking-event-form");
      element.scrollIntoView({ behavior: "smooth" });
    });
  },
  created() {
    this.reloadForm();
  },
  methods: {
    ...mapActions({
      createBooking: "booking/createBooking",
      updateBooking: "booking/updateBooking",
      updateGroupBooking: "booking/updateGroupBooking",
      refreshBookings: "booking/refreshBookings",
      refreshTags: "tag/refreshTags",
    }),
    isServiceLocationTypeSelected(serviceLocationTypeId) {
      const serviceLocation = this.form.serviceLocation;
      return serviceLocation && serviceLocation.type === serviceLocationTypeId;
    },
    reloadForm() {
      // Call the common parts in mixins
      if (
        this.$options.mixins[0] &&
        this.$options.mixins[0].methods.reloadForm
      ) {
        this.$options.mixins[0].methods.reloadForm.call(this);
      }

      this.form.multiSlotGroupId = this.event.multiSlotGroupId;

      const tags = [];
      const products = [];

      if (this.event.tags && this.event.tags.length > 0) {
        for (const index in this.event.tags) {
          const tag = this.event.tags[index];
          if (tag.id) {
            tags.push(tag.id);
          } else {
            tags.push(tag);
          }
        }
      }

      this.form.tags = tags;

      if (this.event.products && this.event.products.length > 0) {
        for (const index in this.event.products) {
          const product = this.event.products[index];
          if (product.id) {
            products.push(product.id);
          }
        }

        this.wantsProducts = true;
      }

      this.form.products = products;
      this.form.user = this.event.user;

      if (!this.form.user && this.selectedUser && this.$route.name === "user") {
        this.form.user = this.$helpers.cloneObject(this.selectedUser);
      }

      this.form.users = this.event.users.filter((user) => {
        return (
          !user.booking_status ||
          user.booking_status === bookingStatuses.approved ||
          user.booking_status === bookingStatuses.payment
        );
      });

      this.form.service = this.event.service;

      this.$nextTick(() => {
        // This will be set to null when setting up the service, so let's set it after a while.
        this.form.serviceLocationId = this.event.location
          ? this.event.location.id
          : null;
      });

      this.form.status = this.event.status
        ? this.event.status
        : bookingStatuses.approved;
      this.form.skipNotifications = false;

      this.form.paid = this.event.paid;
      this.form.googleMeetUrl = this.event.googleMeetUrl;
      this.form.zoomJoinUrl = this.event.zoomJoinUrl;
      this.form.teamsUrl = this.event.teamsUrl;
      this.form.skypeUrl = this.event.skypeUrl;
      this.form.customerWhatsAppUrl = this.event.customerWhatsAppUrl;
      this.form.employeeWhatsAppUrl = this.event.employeeWhatsAppUrl;

      if (this.datesInThePast) {
        this.form.skipNotifications = this.datesInThePast;
      }
    },
    fire() {
      this.setManageEventButtonState(true);

      const action = !this.eventUpdateMode
        ? this.createBooking
        : this.isGroupBookingEventType
          ? this.updateGroupBooking
          : this.updateBooking;

      this.shouldValidate = true;
      if (this.$refs.bookingForm.validate()) {
        if (this.recurringBooking && !this.datesInThePast) {
          this.form.recurrenceParams = `RRULE:FREQ=${this.recurringBookingSelectedFrequency};INTERVAL=${this.recurringBookingRepeatEvery};`;

          if (
            this.recurringBookingEndsSelectedType ===
            this.recurringBookingEndsSelectedTypes.OCCURRENCES
          ) {
            this.form.recurrenceParams += `COUNT=${this.recurringBookingEndsAfter}`;
          } else {
            this.form.recurrenceParams += `UNTIL=${this.$moment(
              this.recurringBookingEndsAt,
            )
              .endOf("day")
              .utc()
              .format("YYYYMMDDTHHmmss")}Z`;
          }
        }

        if (
          !this.eventUpdateMode &&
          this.recurringBooking &&
          this.datesInThePast
        ) {
          this.setManageEventButtonState(false);
          errorNotification(
            "recurring_meeting_in_the_past_warning",
            null,
            false,
          );
          return;
        }

        const eventActionName = "Booking";
        const eventActionType = this.eventUpdateMode
          ? this.isGroupBookingEventType
            ? "UpdateGroup"
            : "Update"
          : "Create";

        const data = this.$helpers.cloneObject(this.form);

        if (this.isGroupBookingEventType) {
          data.user = null;
        } else {
          data.users = null;
        }

        if (!this.wantsProducts) {
          data.products = null;
        }

        if (!this.recurringBooking) {
          data.recurrenceParams = null;
        }

        pushEvent(`eventForm${eventActionType}${eventActionName}`);

        if (this.eventUpdateMode && this.recurringBooking) {
          this.openConfirmDialog({
            type: confirmDialogTypes.EDIT_RECURRING_EVENT,
            data: {
              groupId: this.form.groupId,
              skipNotifications: this.form.skipNotifications,
              disableSkipNotifications: this.datesInThePast,
              isBlockingSpot: false,
              eventData: data,
              isGroupBooking: this.isGroupBookingEventType,
            },
            close: (data) => {
              this.setManageEventButtonState(false);
              if (data && data.success) {
                this.$root.$emit("closeEventModal", true);
              }
            },
          });
        } else {
          action(data)
            .then(() => {
              this.refreshBookings();
              this.refreshTags();
              this.fetchStats();
              this.$root.$emit("closeEventModal", true);
            })
            .catch((error) => {
              if (this.recurringBooking) {
                errorNotification("recurring_booking_ends_info", error, false);
              }

              pushEvent(`eventFormError${eventActionType}${eventActionName}`, {
                error: error,
              });
            })
            .finally(() => {
              this.setManageEventButtonState(false);
            });
        }
      } else {
        errorNotification("form_is_invalid", {}, false);
        this.setManageEventButtonState(false);
      }
    },
    handleNewUserAdded() {
      this.$nextTick(() => {
        this.$refs.bookingForm.resetValidation();
      });
    },
    handleNewTagAdded() {
      this.$nextTick(() => {
        this.$refs.bookingForm.resetValidation();
      });
    },
    handleServiceChange() {
      if (!this.shouldValidate) {
        this.$nextTick(() => {
          this.$refs.bookingForm.resetValidation();
        });
      }
    },
    openManageSimpleStoreProductModal() {
      this.$root.$emit("openManageSimpleStoreProductModal", {
        title: this.$trans("simple_store_product_add_title"),
        ctaButtonIcon: "$plus-circle",
        ctaButtonTitle: this.$trans("add"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-intro {
  min-width: 100px;
}
</style>
