<template>
  <v-radio-group v-model="selectedLocationId" dense hide-details :rules="rules">
    <v-radio
      v-for="itemLocation in form.service.locations"
      :key="itemLocation.id"
      :value="itemLocation.id"
      :disabled="disabled"
    >
      <template #label>
        <div class="d-flex align-center">
          <div
            class="location-icon-container d-flex align-center justify-center"
          >
            <v-icon v-if="getIconForType(itemLocation.type)" color="primary">
              {{ getIconForType(itemLocation.type) }}
            </v-icon>
            <v-img
              v-else
              :src="getImageForType(itemLocation.type)"
              :alt="$trans('service_location_' + itemLocation.type)"
              max-width="20"
              contain
              class="location-img"
            />
          </div>
          <div class="ml-2">
            <span>{{ $trans("service_location_" + itemLocation.type) }}</span>
            <span v-if="itemLocation.name">, {{ itemLocation.name }}</span>
            <span v-if="itemLocation.data">, {{ itemLocation.data }} </span>
          </div>
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import serviceLocationActions from "@/calendesk/mixins/serviceLocationActions";

export default {
  mixins: [serviceLocationActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    form: {
      type: Object,
      default: null,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLocationId: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectedLocationId = val;
    },
    selectedLocationId(locationId) {
      this.$emit("change", locationId);
      this.$emit("input", locationId);
    },
  },
};
</script>

<style scoped>
.location-icon-container {
  width: 24px;
  height: 24px;
  display: inline-flex;
  position: relative;
}

.location-img {
  object-fit: contain;
  object-position: center;
}
</style>
